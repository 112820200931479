import React, { Component } from 'react';
import { BrowserRouter as Router, Link } from "react-router-dom";
import './KeywordsERP.scss'
import ErrorAlert from '../../../../../components/confirm-alert/error-alert'
import { deleteErpConfig, getExportList, getIPList, getKeywordByClient, saveExport } from '../../../../../api/erpConfiguration';

// components
import Header from '../../../../../components/layouts/header/Header'
import Sidebar from '../../../../../components/layouts/sidebar/Sidebar'
import ConfirmAlert from '../../../../../components/confirm-alert/ConfirmAlert'
import LoaderComponent from '../../../../../components/loader/Loader'
import EditMode from '../../../../../components/edit-mode/EditMode';

// plugins
import * as moment from 'moment/min/moment-with-locales'; moment.locale('fr-fr');


// form validation
const ReactFormWithConstraints = require('react-form-with-constraints');

/* eslint-disable import/first */
const { FormWithConstraints, FieldFeedbacks, FieldFeedback } = ReactFormWithConstraints;

import BOAdminDocAtWebERP from '../../../../../components/layouts/BO-Admin-DocAtWeb-ERP/BOAdminDocAtWebERP';
import { getContractKeywords } from '../../../../../api/exports/contracts';

import { t } from 'i18next';
import { withTranslation } from 'react-i18next';
import { event } from 'jquery';

class KeywordsERP extends Component {

    constructor(props) {
        super(props);
        this.state = {
            erpExporKeywordstList: [],
            selectedERPExporKeywords: {},
            default: 123,
            showFormAddERPExporKeywords: false,
            editMode: false,
            selectedERPExporKeywordsIndex: null,
            isNewERPExporKeywords: true,
            scrolling: false,
            actionData: "add", // add | edit | delete
            systemKeywords: [],
            clientKeywords: [],
            otherKeywords: [],
            showSystemKeyword: false,
            showCustomClauses: false,
            initpicklist: {
                systemKeywords: [],
                clientKeywords: [],
                otherKeywords: [],
            },
            picklist1: {
                systemKeywords: [],
                clientKeywords: [],
                otherKeywords: [],
            },
            picklist2: {
                systemKeywords: [],
                clientKeywords: [],
                otherKeywords: [],
            },
            selectedPicklist1: null,
            selectedPicklist2: null,
            listIP:[]
        }

        this.erpExporKeywordstList = this.state.erpExporKeywordstList;
    }

    componentDidMount() {
        this.loadKeywordsByClient();
        LoaderComponent.show();
        getIPList().then((response) => {
            LoaderComponent.hide();
            this.setState({
                ...this,
                listIP: response.data
            }, () => {
                document.getElementById('search-keywords-input').value = ''
            }) 
        });
        getExportList().then((response) => {
            LoaderComponent.hide();
            this.setState({
                ...this,
                erpExporKeywordstList: response.data
            }, () => {
                this.erpExporKeywordstList = [...this.state.erpExporKeywordstList]
                document.getElementById('search-keywords-input').value = ''
            }) 
        });
    }

    loadKeywordsByClient = () => {
        LoaderComponent.show();
        getKeywordByClient().then(keywords=>{
            LoaderComponent.hide();
            this.setState({
                ...this.state,
                systemKeywords : keywords["data"].systemKeywords,
                clientKeywords : keywords["data"].clientKeywords,
                otherKeywords: [
                    {
                       "id": "index",
                        "name": "index"
                    },
                    {
                       "id": "tags",
                        "name": "tags"
                    },
                    {
                       "id": "comments",
                        "name": "comments"
                    }
    
                ],
                initpicklist: {
                    systemKeywords: [...keywords["data"].systemKeywords],
                    clientKeywords: [...keywords["data"].clientKeywords],
                    otherKeywords: [
                        {
                           "id": "index",
                            "name": "index"
                        },
                        {
                           "id": "tags",
                            "name": "tags"
                        },
                        {
                           "id": "comments",
                            "name": "comments"
                        }
        
                    ],
                },
                picklist1: {
                    systemKeywords: [...keywords["data"].systemKeywords],
                    clientKeywords: [...keywords["data"].clientKeywords],
                    otherKeywords: [
                        {
                           "id": "index",
                            "name": "index"
                        },
                        {
                           "id": "tags",
                            "name": "tags"
                        },
                        {
                           "id": "comments",
                            "name": "comments"
                        }
        
                    ],
                },
                picklist2: {
                    systemKeywords: [],
                    clientKeywords: [],
                    otherKeywords: [],
                }
            })
        })
    }

    changeEditMode = (checked) => {
        this.setState({
            ...this,
            editMode: checked
        })
    }

    exitForm = () => {
        return new Promise((resolve, reject) => {

            if (!this.state.showFormAddERPExporKeywords || !this.state.editMode) {
                resolve();
            } else {
                if (this.state.selectedERPExporKeywords !== this.state.erpExporKeywordstList[this.state.selectedERPExporKeywordsIndex]) {
                    ConfirmAlert.show("Voulez vous vraiment faire cette action ?").then(resolve, reject);
                } else {
                    resolve();
                }
            }
        })
    }

    newErpAddress = () => {
        this.exitForm().then(() => {
            document.getElementById("form-keyword").reset();
            this.setState({
                ...this,
                actionData: "add",
                isNewERPExporKeywords: true,
                showFormAddERPExporKeywords: true,
                selectedERPExporKeywords: {}
            },()=>{
                this.loadKeywordsByClient()
            })
        })
    }



    handleChangeInput = e => {
        if (!this.state.selectedERPExporKeywords.hasOwnProperty('name')) {
            this.setState({
                isNewERPExporKeywords: true,
                showFormAddERPExporKeywords: true,
                selectedERPExporKeywords: {}
            })
        }
        this.form.validateFields(e.target);
        this.setState({
            ...this,
            selectedERPExporKeywords: {
                ...this.state.selectedERPExporKeywords,
                [e.target.name]: e.target.value
            }
        });
    }

    addNewErpAddress = () => {

        this.form.validateFields();

        setTimeout(() => {
            if (this.form.isValid()) {
                if (this.state.isNewERPExporKeywords) {
                    LoaderComponent.show();
                    saveExport(this.state.selectedERPExporKeywords, this.state.picklist2).then(response=>{
                        this.setState({
                            ...this,
                            selectedERPExporKeywords: {},
                            selectedERPExporKeywordsIndex: null,
                            erpExporKeywordstList: response.data
                        },()=>{
                            this.erpExporKeywordstList = [...this.state.erpExporKeywordstList]
                            document.getElementById('search-keywords-input').value = ''
                            document.getElementById("form-keyword").reset();
                        });
                        LoaderComponent.hide();
                    }, (error) => {
                        LoaderComponent.hide();
                        ErrorAlert.show(error.response.data, "Erreur API");
                      });

                } else {
                    this.UpdateErpAddress();
                }

            }
        })
    }

    moveItemsBasedOnApiResponse = (picklist1, picklist2, apiResponse) => {
        // Move systemKeywords
        apiResponse.systemKeywords.forEach((id) => {
          const index = picklist1.systemKeywords.findIndex((item) => item.id === id);
          if (index !== -1) {
            picklist2.systemKeywords.push(picklist1.systemKeywords[index]);
            picklist1.systemKeywords.splice(index, 1);
          }
        });
      
        // Move clientKeywords
        apiResponse.clientKeywords.forEach((id) => {
          const index = picklist1.clientKeywords.findIndex((item) => item.id === id);
          if (index !== -1) {
            picklist2.clientKeywords.push(picklist1.clientKeywords[index]);
            picklist1.clientKeywords.splice(index, 1);
          }
        });
      
        // Move otherKeywords
        Object.keys(apiResponse).forEach((key) => {
          if (['index', 'tags', 'comments'].includes(key)) {
            const keyword = picklist1.otherKeywords.find((item) => item.name === key);
            if (keyword && apiResponse[key] === 1) {
              picklist2.otherKeywords.push(keyword);
              picklist1.otherKeywords = picklist1.otherKeywords.filter((item) => item.name !== key);
            }
          }
        });
      
        return { picklist1, picklist2 };
      }
      

    viewAddressInfo = async (address, index) => {

        LoaderComponent.show();
        getKeywordByClient().then(keywords=>{
            LoaderComponent.hide();
            this.setState({
                ...this.state,
                systemKeywords : keywords["data"].systemKeywords,
                clientKeywords : keywords["data"].clientKeywords,
                otherKeywords: [
                    {
                       "id": "index",
                        "name": "index"
                    },
                    {
                       "id": "tags",
                        "name": "tags"
                    },
                    {
                       "id": "comments",
                        "name": "comments"
                    }
    
                ],
                initpicklist: {
                    systemKeywords: [...keywords["data"].systemKeywords],
                    clientKeywords: [...keywords["data"].clientKeywords],
                    otherKeywords: [
                        {
                           "id": "index",
                            "name": "index"
                        },
                        {
                           "id": "tags",
                            "name": "tags"
                        },
                        {
                           "id": "comments",
                            "name": "comments"
                        }
        
                    ],
                },
                picklist1: {
                    systemKeywords: [...keywords["data"].systemKeywords],
                    clientKeywords: [...keywords["data"].clientKeywords],
                    otherKeywords: [
                        {
                           "id": "index",
                            "name": "index"
                        },
                        {
                           "id": "tags",
                            "name": "tags"
                        },
                        {
                           "id": "comments",
                            "name": "comments"
                        }
        
                    ],
                },
                picklist2: {
                    systemKeywords: [],
                    clientKeywords: [],
                    otherKeywords: [],
                }
            },()=>{
                address.ipAddress = (typeof address.ipAddress === "object") ? (address?.ipAddress?.id) : address?.ipAddress 
                if(typeof address.fields === "string")
                    address.fields = JSON.parse(address?.fields)
                const responseList = this.moveItemsBasedOnApiResponse(this.state.picklist1, this.state.picklist2, address.fields);
                
                this.setState({
                    ...this.state,
                    selectedERPExporKeywords: address,
                    isNewERPExporKeywords: false,
                    selectedERPExporKeywordsIndex: index,
                    showFormAddERPExporKeywords: true,
                    picklist1:responseList.picklist1,
                    picklist2:responseList.picklist2
                })
            })
        })

    }

    UpdateErpAddress = () => {
        saveExport(this.state.selectedERPExporKeywords, this.state.picklist2).then(response=>{
            this.setState({
                ...this,
                selectedERPExporKeywords: {},
                selectedERPExporKeywordsIndex: null,
                erpExporKeywordstList: response.data
            },()=>{
                this.erpExporKeywordstList = [...this.state.erpExporKeywordstList]
                document.getElementById('search-keywords-input').value = ''
                document.getElementById("form-keyword").reset();
            });
            LoaderComponent.hide();
        }, (error) => {
            LoaderComponent.hide();
            ErrorAlert.show(error.response.data, "Erreur API");
          });
    }

    viewNextERPAddressIndex = () => {
        // let index = parseInt(this.state.selectedERPExporKeywordsIndex);
        // let erpExporKeywordstList = this.state.erpExporKeywordstList;
        // if (index >= 0 && index < erpExporKeywordstList.length - 1) {
        //     this.setState({
        //         ...this,
        //         selectedERPExporKeywords: erpExporKeywordstList[index + 1],
        //         selectedERPExporKeywordsIndex: [index + 1],
        //         actionData: "edit"
        //     })
        // }
    }

    viewPrevERPAddressIndex = () => {
        // let index = parseInt(this.state.selectedERPExporKeywordsIndex);
        // let erpExporKeywordstList = this.state.erpExporKeywordstList;
        // if (index >= 1 && index <= erpExporKeywordstList.length) {
        //     this.setState({
        //         ...this,
        //         selectedERPExporKeywords: erpExporKeywordstList[index - 1],
        //         selectedERPExporKeywordsIndex: [index - 1],
        //         actionData: "edit"
        //     })
        // }
    }

    sortAddressItems = (sortby, sort) => {
        let erpExporKeywordstList = this.state.erpExporKeywordstList;
        erpExporKeywordstList.sort(function (a, b) {
            if (a[sortby] < b[sortby]) return sort == 'ASC' ? -1 : 1;
            if (a[sortby] > b[sortby]) return sort == 'ASC' ? 1 : -1;
            return 0;
        })
        this.setState({
            ...this,
            erpExporKeywordstList: erpExporKeywordstList
        })
    }

    searchAddressItems = (e) => {
        let erpExporKeywordstList = this.erpExporKeywordstList;
        let searchInput = e.target.value;
        let foundedErpAddress = [];
        erpExporKeywordstList.map((Keyword, index) => {
            if (Keyword.name.toLowerCase().includes(searchInput.toLowerCase())) foundedErpAddress.push(Keyword);
        })
        this.setState({
            ...this,
            erpExporKeywordstList: foundedErpAddress
        })
    }

    handleChange = (e) => {
        this.setState({ ...this.state, [e.target.name]: e.target.value });
    };


    deleteErpAddress = () => {
        ConfirmAlert.show(t('common.modals.confirm_action')).then(
            willDelete => {
                const selectedERPExporKeywordsID = this.state.selectedERPExporKeywords.id;
                deleteErpConfig(selectedERPExporKeywordsID).then(response=>{
                    this.setState({
                        ...this,
                        selectedERPExporKeywords: {},
                        selectedERPExporKeywordsIndex: null,
                        erpExporKeywordstList: this.state.erpExporKeywordstList.filter(x=>x.id !== selectedERPExporKeywordsID) || []
                    },()=>{
                        this.erpExporKeywordstList = [...this.state.erpExporKeywordstList]
                        document.getElementById('search-keywords-input').value = ''
                        document.getElementById("form-keyword").reset();
                    });
                    LoaderComponent.hide();
                }, (error) => {
                    LoaderComponent.hide();
                    ErrorAlert.show(error.response.data, "Erreur API");
                  });
            }
        )
    }


    onScrolling = (e) => {
        this.setState({
            ...this,
            scrolling: e.target.scrollTop > 100 ? true : false
        })
    }

    editModeReminder = () => {
        ConfirmAlert.show(
            "Voulez-vous activer le mode d'édition pour effectuer des modifications ?",
            "Rappel",
            "Activer",
            "Annuler"
        ).then(() => {
            this.setState({
                ...this.state,
                editMode: true,
            });
        });
    };



    setformERPAddress = (e, action) => {
        let name = e.target.name;
        let value = e.target.value;
        let formERPAddress = this.state.formERPAddress
        let findIndex = formERPAddress.findIndex(x => x.name === name);
        if (findIndex > -1) {
            if (Array.isArray(value)) {
                value = value.map(item => `${item.label || item}`).join('\n ');
            }
            formERPAddress[findIndex].value = value

            if (action === "edit") {
                formERPAddress[findIndex].edited = true
            } else {
                formERPAddress[findIndex].edited = false
            }
        } else {
            formERPAddress.push({
                name: name,
                value: value,
                isRequired: true
            })
        }
        this.setState({
            ...this.state,
            actionData: action,
            formERPAddress: formERPAddress
        })
    }
   



    handleSelect = (section, key, picklist, selected) => {
        this.setState(prevState => ({
            ...prevState,
            [picklist]: {
                ...prevState[picklist],
                [section]: Array.isArray(prevState[picklist][section])
                    ? prevState[picklist][section].map((item, index) =>
                        index === key ? { ...item, selected } : item
                    )
                    : {
                        ...prevState[picklist][section],
                        [key]: {
                            ...prevState[picklist][section][key],
                            selected
                        }
                    },
            },
        }));
    }

    toggleSelection = (section, key, picklist) => {
        const currentItem = Array.isArray(this.state[picklist][section])
            ? this.state[picklist][section][key]
            : this.state[picklist][section][key];

        this.handleSelect(section, key, picklist, !currentItem.selected);
    }

    moveSelectedToPicklist2 = () => {
        const { picklist1, picklist2 } = this.state;
        const updatedPicklist1 = { ...picklist1 };
        const updatedPicklist2 = { ...picklist2 };

        ['systemKeywords', 'clientKeywords', 'otherKeywords'].forEach(section => {
            const sourceItems = picklist1[section];
            const selectedItems = Array.isArray(sourceItems)
                ? sourceItems.filter(item => item.selected)
                : Object.entries(sourceItems).filter(([key, item]) => item.selected).map(([key, item]) => ({ ...item, key }));

            if (Array.isArray(sourceItems)) {
                updatedPicklist2[section] = [...updatedPicklist2[section], ...selectedItems];
                updatedPicklist1[section] = sourceItems.filter(item => !item.selected);
            } else {
                updatedPicklist2[section] = {
                    ...updatedPicklist2[section],
                    ...Object.fromEntries(selectedItems.map(item => [item.key, item])),
                };
                updatedPicklist1[section] = Object.fromEntries(
                    Object.entries(sourceItems).filter(([key, item]) => !item.selected)
                );
            }
        });

        this.setState({
            ...this.state,
            picklist1: updatedPicklist1,
            picklist2: updatedPicklist2,
        });
    }

    moveAllToPicklist2 = () => {
        const { picklist1, picklist2 } = this.state;
        this.setState({
            ...this.state,
            picklist2: {
                systemKeywords: [...picklist2.systemKeywords, ...picklist1.systemKeywords],
                clientKeywords: [...picklist2.clientKeywords, ...picklist1.clientKeywords],
                otherKeywords: [...picklist2.otherKeywords, ...picklist1.otherKeywords],
            },
            picklist1: {
                systemKeywords: [],
                clientKeywords: [],
                otherKeywords: [],
            },
        });
    }

    moveSelectedToPicklist1 = () => {
        const { picklist1, picklist2 } = this.state;
        const updatedPicklist1 = { ...picklist1 };
        const updatedPicklist2 = { ...picklist2 };

        ['systemKeywords', 'clientKeywords', 'otherKeywords'].forEach(section => {
            const sourceItems = picklist2[section];
            const selectedItems = Array.isArray(sourceItems)
                ? sourceItems.filter(item => item.selected)
                : Object.entries(sourceItems).filter(([key, item]) => item.selected).map(([key, item]) => ({ ...item, key }));

            if (Array.isArray(sourceItems)) {
                updatedPicklist1[section] = [...updatedPicklist1[section], ...selectedItems];
                updatedPicklist2[section] = sourceItems.filter(item => !item.selected);
            } else {
                updatedPicklist1[section] = {
                    ...updatedPicklist1[section],
                    ...Object.fromEntries(selectedItems.map(item => [item.key, item])),
                };
                updatedPicklist2[section] = Object.fromEntries(
                    Object.entries(sourceItems).filter(([key, item]) => !item.selected)
                );
            }
        });

        this.setState({
            ...this.state,
            picklist1: updatedPicklist1,
            picklist2: updatedPicklist2,
        });
    }

    moveAllToPicklist1 = () => {
        const { picklist1, picklist2 } = this.state;
        this.setState({
            ...this.state,
            picklist1: {
                systemKeywords: [...picklist1.systemKeywords, ...picklist2.systemKeywords],
                clientKeywords: [...picklist1.clientKeywords, ...picklist2.clientKeywords],
                otherKeywords: [...picklist1.otherKeywords, ...picklist2.otherKeywords],
            },
            picklist2: {
                systemKeywords: [],
                clientKeywords: [],
                otherKeywords: [],
            }
        });
    }

    toggleSection = (sectionName) => {
        // Select all elements with the specified class name
        const sectionElements = document.querySelectorAll(`.${sectionName}`);
    
        // Iterate over the NodeList and toggle the 'collapsed' class for each element
        sectionElements.forEach((sectionElement) => {
            sectionElement.classList.toggle('collapsed');
        });
    };

    renderSection = (items, sectionName, picklist, labelName) => (
        
        <div className={`section ${sectionName} collapsed`}>
            <div
                className="section-title"
                onClick={() => this.toggleSection(sectionName)}
            >
                {labelName + " ("+items.length+")"} 
            </div>
            <ul>
                {Array.isArray(items)
                    ? items.map((item, index) => (
                        <li
                            key={index}
                            className={
                                item.selected ? 'selected' : ''
                            }
                            onClick={() => this.toggleSelection(sectionName, index, picklist)}
                        >
                            <input
                                type="checkbox"
                                checked={item.selected || false}
                                onChange={() => this.toggleSelection(sectionName, index, picklist)}
                            />
                            {item.name} 
                        </li>
                    ))
                    : Object.entries(items).map(([key, value]) => (
                        <li
                            key={key}
                            className={
                                this.state[picklist] &&
                                this.state[picklist][sectionName][key]?.selected
                                    ? 'selected'
                                    : ''
                            }
                            onClick={() => this.toggleSelection(sectionName, key, picklist)}
                        >
                            <input
                                type="checkbox"
                                checked={this.state[picklist][sectionName][key]?.selected || false}
                                onChange={() => this.toggleSelection(sectionName, key, picklist)}
                            />
                            {key}: {value}
                        </li>
                    ))}
            </ul>
        </div>
    );
    

    render() {
        const { picklist1, picklist2 } = this.state;

        return (
            <div className="app-container contract-settings page-keywords-erp app-theme-white body-tabs-shadow fixed-header fixed-sidebar closed-sidebar">
                <Header />

                <div className="app-main">
                    <div className="app-sidebar sidebar-shadow">
                        <Sidebar />
                    </div>
                    <div className="app-main__outer">
                        <EditMode
                            isEditMode={this.state.editMode}
                            onChange={(checked) => {
                                this.changeEditMode(checked)
                            }}
                        />
                        <div className="app-main__inner">

                            <div className="row">
                                <div className={`col-sm-5 left-side ${this.state.scrolling ? 'scrolling' : ''}`}>

                                    <h1 className="side-menu-title-style">
                                        <svg width="39" height="33" viewBox="0 0 118 117" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.48738 75.8749H36.6695C36.0137 70.3865 35.6667 64.5491 35.6667 58.4999C35.6667 52.4508 36.0137 46.6134 36.6695 41.1249H9.48738C7.55273 46.5595 6.50003 52.4076 6.50003 58.4999C6.50003 64.5922 7.55273 70.4404 9.48738 75.8749ZM11.9572 81.6666C18.9011 95.5396 31.8871 105.905 47.4968 109.37C42.9519 103.228 39.471 93.465 37.4969 81.6666H11.9572ZM108.513 75.8749C110.447 70.4404 111.5 64.5922 111.5 58.4999C111.5 52.4076 110.447 46.5595 108.513 41.1249H81.3306C81.9863 46.6134 82.3334 52.4508 82.3334 58.4999C82.3334 64.5491 81.9863 70.3865 81.3306 75.8749H108.513ZM106.043 81.6666H80.5032C78.5291 93.465 75.0481 103.228 70.5033 109.37C86.1129 105.905 99.0989 95.5396 106.043 81.6666ZM42.5757 75.8749H75.4244C76.1151 70.4446 76.5 64.5975 76.5 58.4999C76.5 52.4023 76.1151 46.5553 75.4244 41.1249H42.5757C41.8849 46.5553 41.5 52.4023 41.5 58.4999C41.5 64.5975 41.8849 70.4446 42.5757 75.8749ZM43.4541 81.6666C46.5002 98.8553 52.6888 110.625 59 110.625C65.3113 110.625 71.4999 98.8553 74.546 81.6666H43.4541ZM11.9572 35.3333H37.4969C39.471 23.5348 42.9519 13.7722 47.4968 7.63002C31.8871 11.0944 18.9011 21.4602 11.9572 35.3333ZM106.043 35.3333C99.0989 21.4602 86.1129 11.0944 70.5033 7.63002C75.0481 13.7722 78.5291 23.5348 80.5032 35.3333H106.043ZM43.4541 35.3333H74.546C71.4999 18.1446 65.3113 6.37492 59 6.37492C52.6888 6.37492 46.5002 18.1446 43.4541 35.3333ZM59 116.417C26.7834 116.417 0.666695 90.4864 0.666695 58.4999C0.666695 26.5134 26.7834 0.583252 59 0.583252C91.2167 0.583252 117.333 26.5134 117.333 58.4999C117.333 90.4864 91.2167 116.417 59 116.417Z" fill="black" />
                                        </svg>
                                        Web Services
                                    </h1>

                                    <div className="sub-menu">
                                        <BOAdminDocAtWebERP activeMenu="keywords"></BOAdminDocAtWebERP>
                                    </div>
                                    <hr />
                                    <div className="actions side-menu-actions">
                                        <div className="sorting">
                                            <button onClick={() => { this.sortAddressItems('name', 'ASC') }} className="btn btn-sorting">
                                                <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5.64706 3.08294V20.2112C5.64706 20.554 5.33102 20.8318 4.94118 20.8318C4.55133 20.8318 4.23529 20.554 4.23529 20.2112V3.08294L1.20502 5.74683C0.929352 5.98916 0.482412 5.98916 0.206748 5.74683C-0.0689161 5.5045 -0.0689161 5.11159 0.206748 4.86926L4.44204 1.14605C4.71771 0.903711 5.16465 0.903711 5.44031 1.14605L9.67561 4.86926C9.95127 5.11159 9.95127 5.5045 9.67561 5.74683C9.39994 5.98916 8.953 5.98916 8.67734 5.74683L5.64706 3.08294ZM12 3.44644C11.6102 3.44644 11.2941 3.16861 11.2941 2.8259C11.2941 2.48319 11.6102 2.20537 12 2.20537H23.2941C23.684 2.20537 24 2.48319 24 2.8259C24 3.16861 23.684 3.44644 23.2941 3.44644H12ZM12 8.41072C11.6102 8.41072 11.2941 8.1329 11.2941 7.79019C11.2941 7.44747 11.6102 7.16965 12 7.16965H20.4706C20.8604 7.16965 21.1765 7.44747 21.1765 7.79019C21.1765 8.1329 20.8604 8.41072 20.4706 8.41072H12ZM12 13.375C11.6102 13.375 11.2941 13.0972 11.2941 12.7545C11.2941 12.4118 11.6102 12.1339 12 12.1339H17.6471C18.0369 12.1339 18.3529 12.4118 18.3529 12.7545C18.3529 13.0972 18.0369 13.375 17.6471 13.375H12ZM12 18.3393C11.6102 18.3393 11.2941 18.0615 11.2941 17.7188C11.2941 17.376 11.6102 17.0982 12 17.0982H14.8235C15.2134 17.0982 15.5294 17.376 15.5294 17.7188C15.5294 18.0615 15.2134 18.3393 14.8235 18.3393H12Z" fill="#5367F4" />
                                                </svg>
                                            </button>
                                            <button onClick={() => { this.sortAddressItems('name', 'DESC') }} className="btn btn-sorting">
                                                <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M4.84923 20.9721L4.82229 20.9994L4.79982 21.0304C4.76761 21.0749 4.71905 21.1 4.66667 21.1C4.61428 21.1 4.56573 21.0749 4.53352 21.0304L4.51105 20.9994L4.48411 20.9721L0.550913 16.9918C0.483029 16.9231 0.483029 16.8093 0.550914 16.7406C0.61553 16.6752 0.717803 16.6752 0.78242 16.7406L3.64435 19.6368L4.5 20.5027V19.2854V0.674649C4.5 0.572566 4.58021 0.5 4.66667 0.5C4.75312 0.5 4.83333 0.572566 4.83333 0.674649V19.2854V20.5027L5.68898 19.6368L8.55091 16.7406C8.61553 16.6752 8.7178 16.6752 8.78242 16.7406C8.85031 16.8093 8.85031 16.9231 8.78242 16.9918L4.84923 20.9721ZM12.6667 2.19859C12.5802 2.19859 12.5 2.12603 12.5 2.02395C12.5 1.92186 12.5802 1.8493 12.6667 1.8493H23.3333C23.4198 1.8493 23.5 1.92186 23.5 2.02395C23.5 2.12603 23.4198 2.19859 23.3333 2.19859H12.6667ZM12.6667 7.59578C12.5802 7.59578 12.5 7.52322 12.5 7.42114C12.5 7.31905 12.5802 7.24649 12.6667 7.24649H20.6667C20.7531 7.24649 20.8333 7.31905 20.8333 7.42114C20.8333 7.52322 20.7531 7.59578 20.6667 7.59578H12.6667ZM12.6667 12.993C12.5802 12.993 12.5 12.9204 12.5 12.8183C12.5 12.7162 12.5802 12.6437 12.6667 12.6437H18C18.0865 12.6437 18.1667 12.7162 18.1667 12.8183C18.1667 12.9204 18.0865 12.993 18 12.993H12.6667ZM12.6667 18.3902C12.5802 18.3902 12.5 18.3176 12.5 18.2155C12.5 18.1134 12.5802 18.0409 12.6667 18.0409H15.3333C15.4198 18.0409 15.5 18.1134 15.5 18.2155C15.5 18.3176 15.4198 18.3902 15.3333 18.3902H12.6667Z" fill="black" stroke="#5367F4" />
                                                </svg>
                                            </button>
                                        </div>
                                        <div className="search-input">
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12.7488 12.7482L19.0001 19" stroke="#5367F4" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M7.88235 14.7647C11.6834 14.7647 14.7647 11.6834 14.7647 7.88235C14.7647 4.08133 11.6834 1 7.88235 1C4.08133 1 1 4.08133 1 7.88235C1 11.6834 4.08133 14.7647 7.88235 14.7647Z" stroke="#5367F4" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            <input id='search-keywords-input' type="text" placeholder="" onKeyUp={(e) => { this.searchAddressItems(e) }} />
                                        </div>
                                        <button onClick={() => { this.newErpAddress() }} className="btn-icon btn-pill btn btn-danger">
                                            <svg className="inner" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13 7H1M7 1V13V1Z" stroke="#FFF" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                            <svg className="outer" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect width="36" height="36" rx="18" fill="#5367F4"></rect>
                                            </svg>
                                            Ajouter
                                        </button>
                                    </div>
                                    <div className="scrolling-verticaly" onScroll={(e) => { this.onScrolling(e) }}>
                                        {this.state.showFormAddERPExporKeywords &&
                                            <div className="new-keyword add-record">
                                                <h2>{this.state.selectedERPExporKeywords.name || "Web Services : Nouvel Requête"}</h2>
                                                <div>
                                                    <button onClick={() => {

                                                        document.getElementById("form-keyword").reset();
                                                        this.setState({
                                                            ...this,
                                                            isNewERPExporKeywords: true,
                                                            selectedERPExporKeywordsIndex: null,
                                                            selectedERPExporKeywords: {},
                                                            showFormAddERPExporKeywords: false
                                                        },()=>{
                                                            this.loadKeywordsByClient();
                                                        });

                                                    }} className="btn-pill btn btn-cancel">
                                                        Annuler
                                                    </button>
                                                    <button onClick={() => {
                                                        if (this.state.actionData === "edit") {
                                                            this.setState({
                                                                ...this,
                                                                isNewERPExporKeywords: false,
                                                                showResumeModal: true
                                                            });
                                                        } else {
                                                            this.addNewErpAddress()
                                                        }
                                                    }} className="btn-pill btn btn-danger btn-save" disabled={!this.state.editMode || !this.state.selectedERPExporKeywords?.name}>
                                                        Enregistrer
                                                    </button>
                                                </div>
                                            </div>
                                        }

                                        <div className="keywords records">
                                            {this.state.erpExporKeywordstList && this.state.erpExporKeywordstList !== undefined && Array.isArray(this.state.erpExporKeywordstList)  && this.state.erpExporKeywordstList.map((ipItem, index) => {
                                                return (
                                                    <div key={index} onClick={() => { this.viewAddressInfo(ipItem, index) }} className={`keyword mb-0 ${index == this.state.selectedERPExporKeywordsIndex && !this.state.isNewERPExporKeywords ? 'active' : ''}`}>
                                                        <div className="side-menu-style">
                                                            <h3 className="title-stage-one ">{ipItem.name}</h3>
                                                            <p className="title-stage-two ">Séparateur:  <strong>{ipItem.delimiter}</strong></p>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                            {this.state.erpExporKeywordstList.length == 0 &&
                                                <div className="no-resut-found">
                                                    Aucun résultat n'a été trouvé
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-7 viewer">
                                    <h1>Web Services : Requête</h1>
                                    {/* <div className={this.state.isNewERPExporKeywords ? 'hidden' : ''}>
                                        <div className="formHead">
                                            <div className="row">
                                                <div className="col-sm-4 createdAt">
                                                    Créé Aujourd’hui
                                                </div>
                                                <div className="col-sm-4 exportBtns"></div>
                                                <div className="col-sm-4 navigateBtns">
                                                    <button onClick={() => { this.viewPrevERPAddressIndex() }} className="btn btm-light"><i className="fa fa-angle-up"></i></button>
                                                    <span>{parseInt(this.state.selectedERPExporKeywordsIndex) + 1}/{this.state.erpExporKeywordstList.length}</span>
                                                    <button onClick={() => { this.viewNextERPAddressIndex() }} className="btn btm-light"><i className="fa fa-angle-down"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                    <FormWithConstraints
                                        ref={formWithConstraints => this.form = formWithConstraints}
                                        id="form-keyword"
                                        className={!this.state.editMode ? "consultation" : ""}
                                        noValidate>
                                        <div
                                            className="hideFormInputs"
                                            onClick={this.editModeReminder}
                                        ></div>
                                        {
                                            !this.state.isNewERPExporKeywords &&
                                            <div className="row">
                                                <div className="col-lg-12" style={{ textAlign: "right" }}>
                                                    <button onClick={() => { this.deleteErpAddress() }} type="button" className="btn btn-default deleteKeyword">
                                                        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M21.6622 40.7325L21.6819 40.7341H21.7017H22.3009H22.3207L22.3404 40.7325C23.3253 40.6545 24.2891 40.2356 25.0414 39.4779L26.4334 38.0759L26.7831 37.7237L26.4334 37.3714L10.8578 21.6837L10.5029 21.3264L10.1481 21.6837L8.75614 23.0857C7.07954 24.7744 7.07954 27.5108 8.75614 29.1994L18.9612 39.4779C19.7135 40.2356 20.6773 40.6545 21.6622 40.7325ZM26.0578 39.8818L25.2116 40.7341H26.4126H36.9797C37.2521 40.7341 37.4791 40.9575 37.4791 41.2406C37.4791 41.5238 37.2521 41.7471 36.9797 41.7471H22.3716H22.3556L22.3396 41.7482C22.1143 41.7626 21.8883 41.7626 21.663 41.7482L21.647 41.7471H21.631H10.9967C10.7243 41.7471 10.4974 41.5238 10.4974 41.2406C10.4974 40.9575 10.7243 40.7341 10.9967 40.7341H17.59H18.791L17.9448 39.8818L8.05249 29.9183C5.9825 27.8334 5.9825 24.4517 8.05249 22.3668L22.255 8.06217C24.323 5.97928 27.6744 5.97928 29.7424 8.06217L39.9475 18.3407C42.0175 20.4256 42.0175 23.8073 39.9475 25.8922L26.0578 39.8818ZM11.5614 20.2603L11.2116 20.6125L11.5614 20.9648L27.137 36.6525L27.4918 37.0099L27.8466 36.6525L39.2439 25.1733C40.9205 23.4846 40.9205 20.7482 39.2439 19.0596L29.0388 8.78106C27.3602 7.09041 24.6372 7.09041 22.9586 8.78106L11.5614 20.2603Z" fill="black" stroke="#EB3A59" />
                                                            <rect opacity="0.05" width="48" height="48" rx="24" fill="#292C41" />
                                                        </svg>


                                                    </button>
                                                </div>
                                            </div>
                                        }

                                        {this.state.selectedERPExporKeywords.exportCode &&
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <label>Code export</label>
                                                    <input value={this.state.selectedERPExporKeywords.exportCode}  type="text" name="exportCode"  className="form-control" disabled />
                                                    <FieldFeedbacks for="exportCode">
                                                        <FieldFeedback when="*" />
                                                    </FieldFeedbacks>
                                                </div>
                                            </div>
                                        }
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <label>Nom*</label>
                                                <input value={this.state.selectedERPExporKeywords.name} onChange={(e) => { this.handleChangeInput(e); }} type="text" name="name" placeholder="Nom Export" className="form-control" required />
                                                <FieldFeedbacks for="name">
                                                    <FieldFeedback when="*" />
                                                </FieldFeedbacks>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <label>Délimiteur*</label>
                                                <input value={this.state.selectedERPExporKeywords.delimiter} onChange={(e) => { this.handleChangeInput(e); }} type="text" name="delimiter" placeholder="Délimiteur" className="form-control" required />
                                                <FieldFeedbacks for="delimiter">
                                                    <FieldFeedback when="*" />
                                                </FieldFeedbacks>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <label>Mots Clés*</label>
                                                <div className="dual-pick-list">
                                                    <div className="picklist-container">
                                                        <h5>Disponible(s)</h5>
                                                        <div className="keywords-list">
                                                            {this.renderSection(picklist1.systemKeywords, 'systemKeywords', 'picklist1', t('frontoffice.home.contracts.table.export.system_clauses'))}
                                                            {this.renderSection(picklist1.clientKeywords, 'clientKeywords', 'picklist1', t('frontoffice.home.contracts.table.export.custom_clauses'))}
                                                            {this.renderSection(picklist1.otherKeywords, 'otherKeywords', 'picklist1', t('Autres'))}
                                                        </div>
                                                    </div>

                                                    <div className="buttons-container">
                                                        <button onClick={(event) => {event.preventDefault(); this.moveSelectedToPicklist2()}}>{'>'}</button>
                                                        <button onClick={(event) => {event.preventDefault(); this.moveAllToPicklist2()}}>{'>>'}</button>
                                                        <button onClick={(event) => {event.preventDefault(); this.moveSelectedToPicklist1()}}>{'<'}</button>
                                                        <button onClick={(event) => {event.preventDefault(); this.moveAllToPicklist1()}}>{'<<'}</button>
                                                    </div>

                                                    <div className="picklist-container">
                                                        <h5>Sélectionné(s)</h5>
                                                        <div className="keywords-list">
                                                            {this.renderSection(picklist2.systemKeywords, 'systemKeywords', 'picklist2', t('frontoffice.home.contracts.table.export.system_clauses'))}
                                                            {this.renderSection(picklist2.clientKeywords, 'clientKeywords', 'picklist2', t('frontoffice.home.contracts.table.export.custom_clauses'))}
                                                            {this.renderSection(picklist2.otherKeywords, 'otherKeywords', 'picklist2', t('Autres'))}
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <label>IP*</label>
                                                <select value={(this.state.selectedERPExporKeywords.ipAddress && this.state.selectedERPExporKeywords.ipAddress !== undefined) ? this.state.selectedERPExporKeywords?.ipAddress : ""} onChange={ (event) => {this.setState({...this.state, selectedERPExporKeywords:{...this.state.selectedERPExporKeywords, ipAddress: event.target.value} })}} className="form-control" name="ipAddress" required >
                                                    <option value="" disabled>{t("backoffice.history.select")}</option>
                                                    {this.state.listIP.map(ip => (
                                                        <option key={ip.id} value={ip.id}>
                                                            {`${ip.name} (${ip.address}:${ip.portNumber})`}
                                                        </option>
                                                    ))}
                                                </select>
                                                <FieldFeedbacks for="ipAddress">
                                                    <FieldFeedback when="*" />
                                                </FieldFeedbacks>
                                            </div>
                                        </div>

                                        <div style={{ height: 40 }}></div>
                                    </FormWithConstraints>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(KeywordsERP);
