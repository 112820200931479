import React from "react";
import ReactDOM from "react-dom";
import ReactModal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
const customStylesFullScreen = {
  content: {
    top: "0%",
    left: "0%",
    right: "0%",
    bottom: "0%",
    marginRight: "0%",
    transform: "translate(0%, 0%)",
  },
};

const Modal = ({ title, isOpen, onRequestClose, children, footer, isFullScreen=false, styleClass='', subTitle = null }) => (
  <ReactModal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    style={isFullScreen? customStylesFullScreen :customStyles}
    contentLabel="Example Modal"
    className={styleClass}
  >
    <div className="modal-head">
      <h3 className="modal-title">
        {title} 
      </h3>
      <button onClick={onRequestClose} className="close-modal">
        <IconClose />
      </button>
    </div>
      {
          subTitle &&
            <small className="modal-sub-title">{subTitle}</small>
        }
    
    <div className={`modal-content ${isFullScreen ? 'fullwidth-modal':''}`}>{children}</div>
    <div className="modal-footer">{footer}</div>
  </ReactModal>
);

const IconClose = () => (
  <svg
    width="11"
    height="11"
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.69862 1.32285L1.21334 9.85195M1.21334 1.32285L9.69862 9.85195L1.21334 1.32285Z"
      stroke="white"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default Modal;
